<template>
  <div class="px-2 py-4">
    <h1 class="title">Numerical rating scale</h1>
    <b-message type="is-info">
      En escala de 0 (sin prurito) a 10(prurito inimaginable), ¿cómo fue el
      prurito del paciente en el peor momento en las últimas 24&nbsp;h?. Por
      favor seleccione un número
    </b-message>

    <div class="block">
      <div class="columns mt-2">
        <div class="column">
          <b-field id="custom-radio-group" position="is-centered">
            <p class="control">
              <b-radio-button
                v-model="answerOne"
                :native-value="1"
                :disabled="isDisabledInput(1)"
                type="is-info"
              >
                <span>1</span>
              </b-radio-button>
            </p>

            <b-radio-button
              v-model="answerOne"
              :native-value="2"
              :disabled="isDisabledInput(2)"
              type="is-info"
            >
              <span>2</span>
            </b-radio-button>

            <b-radio-button
              v-model="answerOne"
              :native-value="3"
              :disabled="isDisabledInput(3)"
              type="is-info"
            >
              <span>3</span>
            </b-radio-button>

            <b-radio-button
              v-model="answerOne"
              :native-value="4"
              :disabled="isDisabledInput(4)"
              type="is-info"
            >
              <span>4</span>
            </b-radio-button>

            <b-radio-button
              v-model="answerOne"
              :native-value="5"
              :disabled="isDisabledInput(5)"
              type="is-info"
            >
              <span>5</span>
            </b-radio-button>

            <b-radio-button
              v-model="answerOne"
              :native-value="6"
              :disabled="isDisabledInput(6)"
              type="is-info"
            >
              <span>6</span>
            </b-radio-button>

            <b-radio-button
              v-model="answerOne"
              :native-value="7"
              :disabled="isDisabledInput(7)"
              type="is-info"
            >
              <span>7</span>
            </b-radio-button>

            <b-radio-button
              v-model="answerOne"
              :native-value="8"
              :disabled="isDisabledInput(8)"
              type="is-info"
            >
              <span>8</span>
            </b-radio-button>

            <b-radio-button
              v-model="answerOne"
              :native-value="9"
              :disabled="isDisabledInput(9)"
              type="is-info"
            >
              <span>9</span>
            </b-radio-button>

            <b-radio-button
              v-model="answerOne"
              :native-value="10"
              type="is-info"
              :disabled="isDisabledInput(10)"
            >
              10
            </b-radio-button>
          </b-field>
        </div>
        <hr />
      </div>
    </div>

    <hr />

    <b-message type="is-info">
      <strong>Rango de resultados</strong>
      <br />
      {{ lowResult }}
      <br />
      Moderado 4 – 6
      <br />
      Severo >= 7
    </b-message>

    <div class="block has-text-centered title question">
      Resultado: {{ score }} ({{ scoreText }})
      <span v-if="isIncompleted">(Incompleto)</span>
    </div>

    <div class="buttons is-centered" v-if="!queryMode">
      <b-button class="clear-button" icon-right="block-helper" @click="clear"
        >Limpiar</b-button
      >
      <b-button class="save-button" icon-right="check-bold" @click="validate"
        >Guardar</b-button
      >
    </div>
  </div>
</template>

<script>
import { HIS4 as questions } from "../../../data/questions";
import { minValue, maxValue } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      questions: questions,
      answerOne: 0,
      lowResult: "Leve <= 3",
      restoreData: {},
      queryMode: false,
    };
  },
  async created() {
    if (!this.patient && !this.$route.params.surveyId) {
      this.$router.push({ name: "NrsList" }).then(() => {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      });
    }

    if (this.$route.params.surveyId) {
      this.queryMode = true;
      let data = await this.getSurveyData();
      this.setEvaluationAnswers(data);
    }

    if (this.$route.params.temporalData) {
      this.answerOne = this.$route.params.temporalData.answerOne;
    }

    this.$global.$on("restore", () => {
      this.restoreForm();
    });
  },
  computed: {
    score() {
      return parseInt(this.answerOne || 0) * 1;
    },
    scoreText() {
      let text = "";
      if (this.score <= 3) {
        text = "Leve";
      }

      if (this.score >= 4 && this.score <= 6) {
        text = "Moderado";
      }

      if (this.score >= 7 && this.score <= 10) {
        text = "Severo";
      }
      return text;
    },
    isIncompleted() {
      return (
        !this.answerOne < 0 || !this.answerTwo < 0 || !this.answerThree < 0
      );
    },
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    cleanForms() {
      this.answerOne = 0;
    },
    clear() {
      this.restoreData.answerOne = this.answerOne;

      this.cleanForms();
      this.clearTemporalSurveys();

      this.showUndoDialog("Se limpiaron los datos de  la encuesta NRS.");
    },
    isDisabledInput(option) {
      return this.score !== option && this.queryMode;
    },
    async save() {
      try {
        this.changeLoaderStatus(true);
        let data = {
          surveyCode: "NRS",
          result: String(this.score),
          appreciation: this.scoreText,
          doctorId: String(this.userId),
          patientId: String(this.patient.document),
          detail: this.setAnswers(),
        };

        await this.$surveyService.saveSurvey(data);
        this.changeLoaderStatus(false);
        this.showToast(
          "Encuesta enviada satisfactoriamente.",
          "is-success",
          3000
        );
        this.scrollTop();
        this.cleanForms();
        this.clearTemporalSurveys();
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    setAnswers() {
      let question1 = {
        questionCode: questions.HIS4_NODULOS,
        answerCode: null,
        value: this.answerOne,
      };

      return [question1];
    },
    validate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.save();
      } else {
        this.showToast(
          "Hay valores erróneos en las respuestas, por favor corrígelos.",
          "is-warning",
          3000
        );
      }
    },
    restoreForm() {
      this.answerOne = this.restoreData.answerOne;

      this.saveTermporalSulrvey();
    },
    saveTermporalSulrvey() {
      this.$session.set("temporalSurvey", {
        module: {
          name: "HIS4",
          route: "His4",
        },
        survey: {
          answerOne: this.answerOne,
        },
      });
    },
    setEvaluationAnswers(data) {
      data.evaluationDetails.forEach((survey) => {
        if (survey.question.code == "HIS4_NODULOS") {
          this.answerOne = survey.value;
        }
      });
    },
  },
  validations: {
    answerOne: {
      minValue: minValue(0),
      maxValue: maxValue(999),
    },
  },
};
</script>

<style>
#custom-radio-group .has-addons .control:first-child .control .button {
  border-bottom-left-radius: 40px !important;
  border-top-left-radius: 40px !important;
}

#custom-radio-group .has-addons .control:last-child:not(:only-child) .button {
  border-bottom-right-radius: 40px !important;
  border-top-right-radius: 40px !important;
}
</style>
